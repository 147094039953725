import { initializeApp } from "firebase/app";
import Constants from "expo-constants";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = Constants.manifest.web.config.firebase;

export const firebaseApp = initializeApp(firebaseConfig);
firebaseApp.automaticDataCollectionEnabled = false;

export const firebaseAnalytics = getAnalytics(firebaseApp);
