import * as React from "react";
import {
  Text,
  View,
  Image,
  SafeAreaView,
  TouchableOpacity,
} from "react-native";
import { useState, useCallback, useRef } from "react";
import { styles } from "../Style/styles.js";
import { ScrollView } from "react-native-web";
import ReactPlayer from "react-player";
import config from "../Config/ProjectConfig.json";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { OTCMeds_Images } from "../Config/Config_Images";
import { useIsFocused } from "@react-navigation/native";
import { firebaseAnalytics } from "../Config/firebaseConfig";
import { logEvent } from "firebase/analytics";

export default function OTCMedsScreen() {
  const configWebsites = config.OTCMeds_URL_Websites;

  const configVideos = config.OTCMeds_URL_Media;
  const isFocused = useIsFocused();

  const handlePlay = (videoIndex) => {
    switch (videoIndex) {
      case 1:
        setPlaylistPlaying(false);
        setVideoOnePlaying(true);
        setVideoTwoPlaying(false);
        logEvent(firebaseAnalytics, "OTCMeds-" + configVideos[0].label, {
          video_url: configVideos[0].source,
          video_type: "single-video",
          video_page: "Over-the-Counter Medicine",
        });
        break;
      case 2:
        setPlaylistPlaying(false);
        setVideoOnePlaying(false);
        setVideoTwoPlaying(true);
        logEvent(firebaseAnalytics, "OTCMeds-" + configVideos[0].label, {
          video_url: configVideos[1].source,
          video_type: "single-video",
          video_page: "Over-the-Counter Medicine",
        });
        break;
      case -1:
        setPlaylistPlaying(false);
        setVideoOnePlaying(false);
        setVideoTwoPlaying(false);
        break;
    }
  };
  const [video1Playing, setVideoOnePlaying] = useState(false);
  const [video2Playing, setVideoTwoPlaying] = useState(false);
  const [playlistPlaying, setPlaylistPlaying] = useState(false);
  return (
    <ScrollView contentContainerStyle={styles.Container_Content_ScrollView}>
      <View style={styles.Container_Content_Videos}>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {configVideos[0].description}
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
            }}
          >
            <ReactPlayer
              height={0.5625 * wp("75%")}
              width={wp("75%")}
              url={configVideos[0].source}
              playing={video1Playing && isFocused}
              onPlay={() => handlePlay(1)}
            />
          </View>
        </View>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {configVideos[1].description}
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
            }}
          >
            <ReactPlayer
              height={0.5625 * wp("75%")}
              width={wp("75%")}
              url={configVideos[1].source}
              playing={video2Playing && isFocused}
              onPlay={() => handlePlay(2)}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
}
