import React from "react";
import { SafeAreaView } from "react-native-web";
import { Image, View } from "react-native";
import { styles } from "../../../Style/styles";
import { Pressable } from "react-native";
import Text from "react-native-web/dist/vendor/react-native/Animated/components/AnimatedText";
import { ScrollView } from "react-native-web";

//Once clicking on Meditation button within the non-pharmacologic tab, this page will be displayed
//This is the default page with four buttons separating Therapy into subcategories
export default function MeditationHome({ route, navigation }) {
  return (
    <SafeAreaView style={styles.Container_App}>
      <ScrollView contentContainerStyle={styles.Container_Content_ScrollView}>
        {/*<View style={{top: "275px"}}>*/}
        <View style={{ alignItems: "center", flex: 4 }}>
          <View style={styles.Therapy_Row_Views}>
            <Pressable
              style={styles.Therapy_Button}
              onPress={() =>
                navigation.navigate("GuidedImagery", {
                  //itemid: itemId,
                })
              }
            >
              <Image
                style={styles.Therapy_Image}
                source={require("../../../assets/lake-g69ac8032a_640.jpg")}
              />
              <Text style={styles.Therapy_Text}>GUIDED IMAGERY</Text>
            </Pressable>
            <Pressable
              style={styles.Therapy_Button}
              onPress={() =>
                navigation.navigate("Mindfulness", {
                  //itemid: itemId,
                })
              }
            >
              <Image
                style={styles.Therapy_Image}
                source={require("../../../assets/stones-gb0e46b024_640.jpg")}
              />
              <Text style={styles.Therapy_Text}>MINDFULNESS</Text>
            </Pressable>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Pressable
              style={styles.Therapy_Button}
              onPress={() =>
                navigation.navigate("Relaxation", {
                  //itemid: itemId,
                })
              }
            >
              <Image
                style={styles.Therapy_Image}
                source={require("../../../assets/fox-g18d0f6665_640.jpg")}
              />
              <Text style={styles.Therapy_Text}>RELAXATION</Text>
            </Pressable>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
