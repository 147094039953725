import React from "react";
import { SafeAreaView } from "react-native-web";
import { Image, View } from "react-native";
import { styles } from "../../Style/styles";
import { Pressable } from "react-native";
import Text from "react-native-web/dist/vendor/react-native/Animated/components/AnimatedText";
import logo from "../../assets/UNMClogo.png";
import { ScrollView } from "react-native-web";

//Once clicking on Therapy tab, this page will be displayed
//This is the default page with four buttons separating Therapy into subcategories
export default function TherapyHome({ route, navigation }) {
  return (
    <SafeAreaView style={styles.Container_App}>
      <ScrollView contentContainerStyle={styles.Container_Content_ScrollView}>
        {/*<View style={{top: "275px"}}>*/}
        <View style={{ alignItems: "center", flex: 4 }}>
          <View style={styles.Therapy_Row_Views}>
            <Pressable
              style={styles.Therapy_Button}
              onPress={() =>
                navigation.navigate("Aromatherapy", {
                  //itemid: itemId,
                })
              }
            >
              <Image
                style={styles.Therapy_Image}
                source={require("../../assets/natural-medicine-gc8ec88c61_640.jpg")}
              />
              <Text style={styles.Therapy_Text}>AROMATHERAPY</Text>
            </Pressable>
            <Pressable
              style={styles.Therapy_Button}
              onPress={() =>
                navigation.navigate("Meditation", {
                  //itemid: itemId,
                })
              }
            >
              <Image
                style={styles.Therapy_Image}
                source={require("../../assets/stones-g768e8e248_640.jpg")}
              />
              <Text style={styles.Therapy_Text}>MEDITATION</Text>
            </Pressable>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Pressable
              style={styles.Therapy_Button}
              onPress={() =>
                navigation.navigate("Music", {
                  //itemid: itemId,
                })
              }
            >
              <Image
                style={styles.Therapy_Image}
                source={require("../../assets/music-sheet-g17e2fb2e5_640.jpg")}
              />
              <Text style={styles.Therapy_Text}>MUSIC</Text>
            </Pressable>
            <Pressable
              style={styles.Therapy_Button}
              onPress={() =>
                navigation.navigate("SleepBehavior", {
                  //itemid: itemId,
                })
              }
            >
              <Image
                style={styles.Therapy_Image}
                source={require("../../assets/cat-g119d2830c_640.jpg")}
              />
              <Text style={styles.Therapy_Text}>SLEEP BEHAVIOR</Text>
            </Pressable>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
