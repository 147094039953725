//If you change the order of any of these images you also need to change the position of each of the images so that correct sites can be loaded
//

const postopPainMgmt_Images = [];

const OTCMeds_Images = [];
const Opioid_Images = [
  {
    name: "USDJ_DiversionControlDivision",
    uri: require("../assets/Website_Content_Images/USDJ_DiversionControlDivision.png"),
    screen: "Opioids",
  },
  {
    name: "Nebraska_DHHS_Opioid",
    uri: require("../assets/Website_Content_Images/Nebraska_DHHS_Opioid.png"),
    screen: "Opioids",
  },
  {
    name: "SAMHSAHelpLine",
    uri: require("../assets/Website_Content_Images/SAMHSAHelpLine.png"),
    screen: "Opioids",
  },
];

export { postopPainMgmt_Images };
export { OTCMeds_Images };
export { Opioid_Images };
