import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";

//Import statements for screens
import MeditationHome from "../screens/TherapySubCategories/MeditationSubCategories/MeditationHome";
import GuidedImagery from "../screens/TherapySubCategories/MeditationSubCategories/GuidedImagery";
import Mindfulness from "../screens/TherapySubCategories/MeditationSubCategories/Mindfulness";
import Relaxation from "../screens/TherapySubCategories/MeditationSubCategories/Relaxation";

const Stack = createStackNavigator();
const headerTextSize = hp("4%") < wp("4%") ? hp("4%") : wp("4%");

const MeditationSubCats = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="MeditationHome"
        component={MeditationHome}
        options={{
          headerShown: false,
          headerStyle: {
            backgroundColor: "#989EA4",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          title: "Meditation Home",
          headerTintColor: "white",
          headerTitleAlign: "center",
        }}
      />
      <Stack.Screen
        name="GuidedImagery"
        component={GuidedImagery}
        options={{
          headerStyle: {
            height: hp("6.5%"),
            backgroundColor: "#989EA4",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
            alignSelf: "center",
          },
          headerTintColor: "white",
          title: "Guided Imagery",
          headerTitleAlign: "center",
        }}
      />
      <Stack.Screen
        name="Mindfulness"
        component={Mindfulness}
        options={{
          headerStyle: {
            height: hp("6.5%"),
            backgroundColor: "#989EA4",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTintColor: "white",
          title: "Mindfulness",
          headerTitleAlign: "center",
        }}
      />
      <Stack.Screen
        name="Relaxation"
        component={Relaxation}
        options={{
          headerStyle: {
            height: hp("6.5%"),
            backgroundColor: "#989EA4",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTintColor: "white",
          title: "Relaxation",
          headerTitleAlign: "center",
        }}
      />
    </Stack.Navigator>
  );
};
export default MeditationSubCats;
