import * as React from "react";
import { useState, useCallback, useRef } from "react";
import { Text, View, SafeAreaView } from "react-native";
import { styles } from "../Style/styles.js";
import { ScrollView } from "react-native-web";
import ReactPlayer from "react-player";
import config from "../Config/ProjectConfig.json";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { postopPainMgmt_Images } from "../Config/Config_Images";
import { useIsFocused } from "@react-navigation/native";
import { firebaseAnalytics } from "../Config/firebaseConfig";
import { logEvent } from "firebase/analytics";

export default function PostopPainMgmtScreen() {
  const configVideos = config.PostopPainMgmt_URL_Media;
  const configWebsites = config.PostopPainMgmt_URL_Websites;
  console.log(configVideos[0].source);
  const isFocused = useIsFocused();

  const handlePlay = (videoIndex) => {
    switch (videoIndex) {
      case 1:
        setPlaylistPlaying(false);
        setVideoOnePlaying(true);
        setVideoTwoPlaying(false);
        setVideoThreePlaying(false);
        logEvent(firebaseAnalytics, "Postop-" + configVideos[0].label, {
          video_url: configVideos[0].source,
          video_type: "single-video",
          video_page: "Postoperative Pain Management",
        });
        break;
      case 2:
        setPlaylistPlaying(false);
        setVideoOnePlaying(false);
        setVideoTwoPlaying(true);
        setVideoThreePlaying(false);
        logEvent(firebaseAnalytics, "Postop-" + configVideos[1].label, {
          video_url: configVideos[1].source,
          video_type: "single-video",
          video_page: "Postoperative Pain Management",
        });
        break;
      case 3:
        setPlaylistPlaying(false);
        setVideoOnePlaying(false);
        setVideoTwoPlaying(false);
        setVideoThreePlaying(true);
        logEvent(firebaseAnalytics, "Postop-" + configVideos[2].label, {
          video_url: configVideos[2].source,
          video_type: "single-video",
          video_page: "Postoperative Pain Management",
        });
        break;
      case -1:
        setPlaylistPlaying(false);
        setVideoOnePlaying(false);
        setVideoTwoPlaying(false);
        setVideoThreePlaying(false);
        break;
    }

    // console.log("video index to be changed ", videoIndex);
    // console.log("Playlist playing?  ", playlistPlaying);
    // console.log("video 1 playing?  ", video1Playing);
    // console.log("video 2 playing?  ", video2Playing);
    // console.log("video 3 playing?  ", video3Playing);
  };

  const [video1Playing, setVideoOnePlaying] = useState(false);
  const [video2Playing, setVideoTwoPlaying] = useState(false);
  const [video3Playing, setVideoThreePlaying] = useState(false);
  const [playlistPlaying, setPlaylistPlaying] = useState(false);

  return (
    <ScrollView contentContainerStyle={styles.Container_Content_ScrollView}>
      <View style={styles.Container_Content_Videos}>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {configVideos[0].description}
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
            }}
          >
            <ReactPlayer
              height={0.5625 * wp("75%")}
              width={wp("75%")}
              url={configVideos[0].source}
              playing={video1Playing && isFocused}
              onPlay={() => handlePlay(1)}
            />
          </View>
        </View>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {configVideos[1].description}
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
            }}
          >
            <ReactPlayer
              height={0.5625 * wp("75%")}
              width={wp("75%")}
              url={configVideos[1].source}
              playing={video2Playing && isFocused}
              onPlay={() => handlePlay(2)}
            />
          </View>
        </View>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {configVideos[2].description}
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
            }}
          >
            <ReactPlayer
              height={0.5625 * wp("75%")}
              width={wp("75%")}
              url={configVideos[2].source}
              playing={video3Playing && isFocused}
              onPlay={() => handlePlay(3)}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
}
