//This class provides a function to provide the smaller font size depending on whether or not the screen is
//taller than wide.

export function compareResponsiveSizes(widthSize, heightSize) {
  if (widthSize < heightSize) {
    return widthSize;
  } else {
    return heightSize;
  }
}
