import * as React from "react";
import { Button, View } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import {
  NavigationContainer,
  DefaultTheme,
  useNavigationContainerRef,
} from "@react-navigation/native";
import HomeScreen from "../screens/HomeScreen";
import PostopPainMgmtScreen from "../screens/PostopPainMgmt";
import OpioidScreen from "../screens/OpioidsScreen";
import OTCMedsScreen from "../screens/OTCMedsScreen";
import TherapyScreen from "../screens/TherapyScreen";
import PlayAllVideosScreen from "../screens/PlayAllVideos";
import { styles } from "../Style/styles";
import { AboutPage } from "../screens/AboutPage";
import { firebaseAnalytics } from "../Config/firebaseConfig";
import { logEvent } from "firebase/analytics";

//firebase analytics

const Drawer = createDrawerNavigator();

const MyTheme = {
  dark: false,
  colors: {
    primary: "white",
    background: "rgb(242, 242, 242)",
    card: "#005E63",
    text: "#ffffff",
    border: "#ffffff",
    notification: "rgb(255, 69, 58)",
  },
};

const DrawerNav = () => {
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();
  return (
    <NavigationContainer
      theme={MyTheme}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.current.getCurrentRoute().name;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        if (previousRouteName !== currentRouteName) {
          await logEvent(firebaseAnalytics, "screen-change", {
            screen_name: currentRouteName,
            screen_class: currentRouteName,
          });
        }
        routeNameRef.current = currentRouteName;
      }}
    >
      <Drawer.Navigator initialRouteName="Home">
        <Drawer.Screen name="Home" component={HomeScreen} />
        <Drawer.Screen
          name="Play All Content"
          component={PlayAllVideosScreen}
        />
        <Drawer.Screen
          name="Postoperative Pain Information"
          component={PostopPainMgmtScreen}
        />
        <Drawer.Screen
          name="Opioid Safety and Disposal"
          component={OpioidScreen}
        />
        <Drawer.Screen
          name="Over-the-Counter Pain Medicine"
          component={OTCMedsScreen}
        />
        <Drawer.Screen
          name="Non-Pharmacologic Therapy"
          component={TherapyScreen}
        />
        <Drawer.Screen name="About" component={AboutPage} />
      </Drawer.Navigator>
    </NavigationContainer>
  );
};

export default DrawerNav;
