import * as React from "react";
import {
  Text,
  View,
  Image,
  SafeAreaView,
  TouchableOpacity,
  Linking,
} from "react-native";
import { styles } from "../Style/styles.js";
import { ScrollView } from "react-native-web";
import ReactPlayer from "react-player";
import config from "../Config/ProjectConfig.json";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { Opioid_Images } from "../Config/Config_Images";
import { useIsFocused } from "@react-navigation/native";
import { useState, useCallback, useRef } from "react";
import { firebaseAnalytics } from "../Config/firebaseConfig";
import { logEvent } from "firebase/analytics";

export default function OpioidScreen() {
  const websites = config.Opioid_URL_Websites;
  const videos = config.Opioid_URL_Media;
  const isFocused = useIsFocused();

  const handlePlay = (videoIndex) => {
    switch (videoIndex) {
      case 1:
        setPlaylistPlaying(false);
        setVideoOnePlaying(true);
        setVideoTwoPlaying(false);
        setVideoThreePlaying(false);
        logEvent(firebaseAnalytics, "Opioid-" + videos[0].label, {
          video_url: videos[0].source,
          video_type: "single-video",
          video_page: "Opioid Safety and Disposal",
        });
        break;
      case 2:
        setPlaylistPlaying(false);
        setVideoOnePlaying(false);
        setVideoTwoPlaying(true);
        setVideoThreePlaying(false);
        logEvent(firebaseAnalytics, "Opioid-" + videos[1].label, {
          video_url: videos[1].source,
          video_type: "single-video",
          video_page: "Opioid Safety and Disposal",
        });
        break;
      case 3:
        logEvent(firebaseAnalytics, "Opioid-" + videos[2].label, {
          video_url: videos[2].source,
          video_type: "single-video",
          video_page: "Opioid Safety and Disposal",
        });
        break;
      case -1:
        setPlaylistPlaying(false);
        setVideoOnePlaying(false);
        setVideoTwoPlaying(false);
        setVideoThreePlaying(false);
        break;
    }
  };

  const [video1Playing, setVideoOnePlaying] = useState(false);
  const [video2Playing, setVideoTwoPlaying] = useState(false);
  const [video3Playing, setVideoThreePlaying] = useState(false);
  const [playlistPlaying, setPlaylistPlaying] = useState(false);

  return (
    <ScrollView contentContainerStyle={styles.Container_Content_ScrollView}>
      <View style={styles.Container_Content_Videos}>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {videos[0].description}
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
            }}
          >
            <ReactPlayer
              height={0.5625 * wp("75%")}
              width={wp("75%")}
              url={videos[0].source}
              playing={video1Playing && isFocused}
              onPlay={() => handlePlay(1)}
            />
          </View>
        </View>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {videos[1].description}
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
            }}
          >
            <ReactPlayer
              height={0.5625 * wp("75%")}
              width={wp("75%")}
              url={videos[1].source}
              playing={video2Playing && isFocused}
              onPlay={() => handlePlay(2)}
            />
          </View>
        </View>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {videos[2].description}
            </Text>
          </View>
          <View
            style={{
              alignSelf: "center",
            }}
          >
            <ReactPlayer
              height={0.5625 * wp("75%")}
              width={wp("75%")}
              url={videos[2].source}
              playing={video3Playing && isFocused}
              onPlay={() => handlePlay(3)}
            />
          </View>
        </View>
      </View>

      {/* this is the start of the website links */}

      <View style={styles.Container_Content_Videos}>
        <View key={websites[0].source} style={styles.Container_Website}>
          <a href={websites[0].source}>
            <TouchableOpacity
              onPress={() =>
                logEvent(firebaseAnalytics, "Opioid-" + websites[0].label, {
                  website_url: websites[0].source,
                })
              }
            >
              <View style={styles.Container_WebsiteDescription}>
                <Text style={styles.Text_VideoDescriptions}>
                  {websites[0].description}
                </Text>
              </View>
              <Image
                source={Opioid_Images[websites[0].imagePosition]}
                style={styles.Website_Preview}
              />
            </TouchableOpacity>
          </a>
        </View>
        <View key={websites[1].source} style={styles.Container_Website}>
          <a href={websites[1].source}>
            <TouchableOpacity
              onPress={() =>
                logEvent(firebaseAnalytics, "Opioid-" + websites[1].label, {
                  website_url: websites[1].source,
                })
              }
            >
              <View style={styles.Container_WebsiteDescription}>
                <Text style={styles.Text_VideoDescriptions}>
                  {websites[1].description}
                </Text>
              </View>
              <Image
                source={Opioid_Images[websites[1].imagePosition]}
                style={styles.Website_Preview}
              />
            </TouchableOpacity>
          </a>
        </View>
        <View key={websites[2].source} style={styles.Container_Website}>
          <a href={websites[2].source}>
            <TouchableOpacity
              onPress={() =>
                logEvent(firebaseAnalytics, "Opioid-" + websites[2].label, {
                  website_url: websites[2].source,
                })
              }
            >
              <View style={styles.Container_WebsiteDescription}>
                <Text style={styles.Text_VideoDescriptions}>
                  {websites[2].description}
                </Text>
              </View>
              <Image
                source={Opioid_Images[websites[2].imagePosition]}
                style={styles.Website_Preview}
              />
            </TouchableOpacity>
          </a>
        </View>
      </View>
    </ScrollView>
  );
}
