import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";

//Import statements for screens
import TherapyHome from "../screens/TherapySubCategories/TherapyHome";
import Aromatherapy from "../screens/TherapySubCategories/Aromatherapy";
import Meditation from "./MeditationSubCats";
import Music from "../screens/TherapySubCategories/Music";
import SleepBehavior from "../screens/TherapySubCategories/SleepBehavior";
import { ScrollView } from "react-native-web";
import {
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { firebaseAnalytics } from "../Config/firebaseConfig";
import { logEvent } from "firebase/analytics";

const Stack = createStackNavigator();
const headerTextSize = hp("4%") < wp("4%") ? hp("4%") : wp("4%");

const TherapySubCats = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="TherapyHome"
        component={TherapyHome}
        options={{
          headerShown: false,
          headerStyle: {
            backgroundColor: "#989EA4",
            // justifyContent: "center",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          title: "Non Pharmacologic Therapy",
          headerTintColor: "white",
          headerTitleAlign: "center",
        }}
      />
      <Stack.Screen
        name="Aromatherapy"
        component={Aromatherapy}
        options={{
          headerStyle: {
            height: hp("6.5%"),
            backgroundColor: "#989EA4",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
            alignSelf: "center",
          },
          headerTintColor: "white",
          title: "Aromatherapy",
          headerTitleAlign: "center",
        }}
      />
      <Stack.Screen
        name="Meditation"
        component={Meditation}
        options={{
          headerStyle: {
            height: hp("6.5%"),
            backgroundColor: "#989EA4",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTintColor: "white",
          title: "Meditation",
          headerTitleAlign: "center",
        }}
      />
      <Stack.Screen
        name="Music"
        component={Music}
        options={{
          headerStyle: {
            height: hp("6.5%"),
            backgroundColor: "#989EA4",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTintColor: "white",
          title: "Music",
          headerTitleAlign: "center",
        }}
      />
      <Stack.Screen
        name="SleepBehavior"
        component={SleepBehavior}
        options={{
          headerStyle: {
            height: hp("6.5%"),
            backgroundColor: "#989EA4",
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTintColor: "white",
          title: "Sleep Behavior",
          headerTitleAlign: "center",
        }}
      />
    </Stack.Navigator>
  );
};
export default TherapySubCats;
