import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import React from "react";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { compareResponsiveSizes as crs } from "../Functions/ResponsiveAdjustment";

//Import statements for screens
import HomeScreen from "../screens/HomeScreen";
import PostopPainMgmtScreen from "../screens/PostopPainMgmt";
import OpioidScreen from "../screens/OpioidsScreen";
import OTCMedsScreen from "../screens/OTCMedsScreen";
import TherapyScreen from "../screens/TherapyScreen";
import { styles } from "../Style/styles";

const Tab = createBottomTabNavigator();
const iconSize = crs(wp("7%"), hp("7%")) < 40 ? crs(wp("7%"), hp("7%")) : 40;
const textSize = crs(wp(".5%"), hp(".5%"));
const textWidth = wp("19%%");
const headerTextSize = hp("4%") < wp("4%") ? hp("4%") : wp("4%");

const Tabs = () => {
  return (
    <Tab.Navigator
      /*tabBarOptions={{
        showLabel: false,
        style: {
          position: "absolute",
          bottom: 25,
          left: 20,
          right: 20,
          elevation: 0,
          backgroundColor: "#ffffff",
          height: 90,
          ...style.shadow,
        },
      }}*/
      screenOptions={{
        tabBarShowLabel: false,
        tabBarActiveBackgroundColor: "white",
        tabBarInactiveBackgroundColor: "#989EA4",
        // tabBarStyle: { position: "absolute" },
        tabBarStyle: [
          {
            display: "flex",
            // height: hp("12%") > wp("6%") ? hp("12%") : wp("6%"),
            height: hp("5%"),
            shadowColor: "#989EA4",
            // shadowOffset: {
            //   width: 0,
            //   height: 10,
            // },
            shadowOpacity: 0.25,
            shadowRadius: 3.5,
            elevation: 5,
          },
          null,
        ],
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerStyle: {
            backgroundColor: "#989EA4",
            height: hp("5%"),
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTitleAlign: "center",
          tabBarIcon: ({ focused }) => (
            <View style={styles.Tabs_Labels}>
              {/* <Image
                source={require("../assets/icons/homeIcon.png")}
                resizeMode="contain"
                style={{
                  width: iconSize,
                  height: iconSize,
                  tintColor: focused ? "#005E63" : "black",
                }}
              /> */}
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                }}
              >
                {" "}
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                HOME
              </Text>
            </View>
          ),
        }}
      />
      <Tab.Screen
        name="Postoperative Pain Information"
        component={PostopPainMgmtScreen}
        options={{
          headerStyle: {
            backgroundColor: "#989EA4",
            height: hp("5%"),
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTitleAlign: "center",
          tabBarIcon: ({ focused }) => (
            <View style={styles.Tabs_Labels}>
              {/* <Image
                source={require("../assets/icons/folder.png")}
                resizeMode="contain"
                style={{
                  width: iconSize,
                  height: iconSize,
                  tintColor: focused ? "#005E63" : "black",
                }}
              /> */}

              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                POSTOPERATIVE
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                PAIN
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  fontWeight: "bold",
                  width: textWidth,
                }}
              >
                INFORMATION
              </Text>
            </View>
          ),
        }}
      />
      <Tab.Screen
        name="Opioid Safety and Disposal"
        component={OpioidScreen}
        options={{
          headerStyle: {
            backgroundColor: "#989EA4",
            height: hp("5%"),
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTitleAlign: "center",
          tabBarIcon: ({ focused }) => (
            <View style={styles.Tabs_Labels}>
              {/* <Image
                source={require("../assets/icons/folder.png")}
                resizeMode="contain"
                style={{
                  width: iconSize,
                  height: iconSize,
                  tintColor: focused ? "#005E63" : "black",
                }}
              /> */}
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                OPIOID
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                {"SAFETY &"}
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                DISPOSAL
              </Text>
            </View>
          ),
        }}
      />
      <Tab.Screen
        name="Over The Counter Pain Medicine"
        component={OTCMedsScreen}
        options={{
          headerStyle: {
            backgroundColor: "#989EA4",
            height: hp("5%"),
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerTitleAlign: "center",
          tabBarIcon: ({ focused }) => (
            <View style={styles.Tabs_Labels}>
              {/* <Image
                source={require("../assets/icons/folder.png")}
                resizeMode="contain"
                style={{
                  width: iconSize,
                  height: iconSize,
                  tintColor: focused ? "#005E63" : "white",
                }}
              /> */}
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                OVER THE
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                COUNTER PAIN
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                MEDICINE
              </Text>
            </View>
          ),
        }}
      />
      <Tab.Screen
        name="Non Pharmacologic Therapy"
        component={TherapyScreen}
        options={{
          headerStyle: {
            backgroundColor: "#989EA4",
            height: hp("5%"),
          },
          headerTitleStyle: {
            fontSize: headerTextSize,
            color: "white",
          },
          headerShown: false,
          headerTitleAlign: "center",
          tabBarIcon: ({ focused }) => (
            <View style={styles.Tabs_Labels}>
              {/* <Image
                source={require("../assets/icons/folder.png")}
                resizeMode="contain"
                style={{
                  width: iconSize,
                  height: iconSize,
                  tintColor: focused ? "#005E63" : "black",
                }}
              /> */}
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                NON
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                PHARMACOLOGIC
              </Text>
              <Text
                style={{
                  color: focused ? "#005E63" : "black",
                  fontSize: textSize,
                  width: textWidth,
                  fontWeight: "bold",
                }}
              >
                THERAPY
              </Text>
            </View>
          ),
        }}
      />
    </Tab.Navigator>
  );
};

const style = StyleSheet.create({
  shadow: {
    shadowColor: "#7f5df0",
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.5,
    elevation: 5,
  },
});

export default Tabs;
