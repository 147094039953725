import * as React from "react";
import { Text, View, Image, SafeAreaView } from "react-native";
import { styles } from "../../../Style/styles.js";
import { ScrollView } from "react-native-web";
import ReactPlayer from "react-player";
import config from "../../../Config/ProjectConfig.json";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { useIsFocused } from "@react-navigation/native";
import { useState, useCallback, useRef } from "react";
import { firebaseAnalytics } from "../../../Config/firebaseConfig";
import { logEvent } from "firebase/analytics";

export default function GuidedImagery() {
  const videos = config.Therapy_Meditation_URL_Media;
  const isFocused = useIsFocused();

  const handlePlay = (videoIndex) => {
    switch (videoIndex) {
      case 1:
        setPlaylistPlaying(false);
        setVideoOnePlaying(true);
        setVideoTwoPlaying(false);
        logEvent(firebaseAnalytics, "GuidedImagery-" + videos[0].label, {
          video_url: videos[0].source,
          video_type: "single-video",
          video_page: "Meditation - Guided Imagery",
        });
        break;
      case 2:
        setPlaylistPlaying(false);
        setVideoOnePlaying(false);
        setVideoTwoPlaying(true);
        logEvent(firebaseAnalytics, "GuidedImagery-" + videos[1].label, {
          video_url: videos[1].source,
          video_type: "single-video",
          video_page: "Meditation - Guided Imagery",
        });
        break;
    }
  };
  const [video1Playing, setVideoOnePlaying] = useState(false);
  const [video2Playing, setVideoTwoPlaying] = useState(false);
  const [playlistPlaying, setPlaylistPlaying] = useState(false);
  return (
    <SafeAreaView style={styles.Container_App}>
      <ScrollView contentContainerStyle={styles.Container_Content_ScrollView}>
        <View style={styles.Container_Content_Videos}>
          <View style={styles.Container_Video}>
            <View style={styles.Container_VideoDescription}>
              <Text style={styles.Text_VideoDescriptions}>
                {videos[0].description}
              </Text>
            </View>
            <View
              style={{
                alignSelf: "center",
              }}
            >
              <ReactPlayer
                height={0.5625 * wp("75%")}
                width={wp("75%")}
                url={videos[0].source}
                playing={video1Playing && isFocused}
                onPlay={() => handlePlay(1)}
              />
            </View>
          </View>
          <View style={styles.Container_Video}>
            <View style={styles.Container_VideoDescription}>
              <Text style={styles.Text_VideoDescriptions}>
                {videos[1].description}
              </Text>
            </View>
            <View
              style={{
                alignSelf: "center",
              }}
            >
              <ReactPlayer
                height={0.5625 * wp("75%")}
                width={wp("75%")}
                url={videos[1].source}
                playing={video2Playing && isFocused}
                onPlay={() => handlePlay(2)}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
