import { StyleSheet, Platform, Dimensions } from "react-native";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { compareResponsiveSizes as crs } from "../Functions/ResponsiveAdjustment";

const window = Dimensions.get("window");

export const styles = StyleSheet.create({
  AboutBodyContainer: {
    flex: 3,
    // flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    // textAlign: "center",
  },
  AboutContainer: {
    flex: 1,
    // flexDirection: "column",
    // justifyContent: "space-evenly",
    // alignContent: "center",
  },
  AboutPage_Component_Container: {
    flex: 7,
    paddingVertical: hp("1%"),
  },
  AboutPage_Close_Button_Container: {
    flex: 1,
    paddingVertical: hp("1%"),
    justifyContent: "center",
  },
  AboutLogoContainer: {
    flex: 1,
    // flexDirection: "column",
    // justifyContent: "center",
    textAlign: "center",
  },
  AboutPageHeaders: {
    fontSize: crs(wp("4%"), hp("4%")),
    fontWeight: "bold",
    color: "#000",
    flex: 1,
  },
  AboutPageWords: {
    fontSize: crs(wp("4%"), hp("4%")),
    color: "#000",
    flex: 1,
  },
  AboutTitleContainer: {
    flex: 1,
    alignItems: "center",
  },
  Button_Container: {
    elevation: 8,
    height: hp("5%"),
    width: wp("25%") < 300 ? wp("25%") : 300,
    backgroundColor: "#005E63",
    borderRadius: hp("3%"),
    paddingVertical: 10,
    alignSelf: "center",
    textAlign: "center",
  },
  Button_Text: {
    fontSize: crs(wp("3%"), hp("3%")),
    color: "#fff",
    textAlignVertical: "center",
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase",
  },
  Button_Subfolder_Container: {
    elevation: 8,
    height: hp("5%"),
    width: wp("20%"),
    backgroundColor: "#edf5f8",
    borderRadius: hp("3%"),
    paddingVertical: 10,
    alignSelf: "center",
    textAlign: "center",
  },
  Button_Subfolder_Text: {
    fontSize: crs(wp("3%"), hp("3%")),
    color: "#fff",
    textAlignVertical: "center",
    textAlign: "center",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase",
  },
  Button_Play: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    padding: 10,
  },
  Container_About_Search: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderBottomColor: "#1A374D",
    borderTopColor: "#1A374D",
    //borderTopWidth: hp(".5%"),
    borderBottomWidth: hp(".5%"),
  },
  Container_About_Search_Subfolder: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderBottomColor: "#1A374D",
    borderTopColor: "#1A374D",
    //borderTopWidth: hp(".5%"),
    borderBottomWidth: hp(".5%"),
  },
  Container_About_Search_Home: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Container_App: {
    flex: 1,
    justifyContent: "space-evenly",
    alignContent: "center",
  },
  Container_Logo: {
    flex: 1,
    alignContent: "center",
    justifyContent: "center",
  },
  Container_Content_OuterView: {
    flex: 4,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Container_Content_ScrollView: {
    flexGrow: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Container_Content_ScrollView_Home: {
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Container_Content_ScrollView_AromaRelax: {
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Container_Content_Videos: {
    flexGrow: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Container_Content_Videos_Home: {
    flexGrow: 2,
    justifyContent: "space-evenly",
    alignItems: "center",
    marginVertical: hp("2%"),
  },
  Container_Content_Audio: {
    flexGrow: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  Container_Video: {
    flex: 1,
    padding: crs(wp("3%"), hp("3%")),
    alignContent: "center",
    // textAlign: "center",
    justifyContent: "center",
    margin: hp("2%"),
    borderTopColor: "#005E63",
    borderTopWidth: hp("1%"),
  },
  Container_Website: {
    // flex: 1,
    padding: crs(wp("3%"), hp("3%")),
    alignContent: "center",
    // textAlign: "center",
    justifyContent: "center",
    margin: hp("2%"),
    borderTopColor: "#005E63",
    borderTopWidth: hp("1%"),
  },
  Container_Video_AromaRelax: {
    flex: 1,
    padding: crs(wp("3%"), hp("3%")),
    alignContent: "center",
    // textAlign: "center",
    justifyContent: "center",
    margin: hp("2%"),
    // borderTopColor: "#005E63",
    // borderTopWidth: hp("1%"),
  },
  Container_VideoDescription: {
    // flex: 1,
    width: wp("75%"),
    // textAlign: "center",
    alignSelf: "center",
    paddingVertical: hp("1%"),
  },
  Container_WebsiteDescription: {
    // flex: 1,
    width: wp("75%"),
    // textAlign: "center",
    alignSelf: "center",
    // paddingVertical: hp("1%"),
  },
  Container_Audio: {
    flex: 1,
    padding: crs(wp("3%"), hp("3%")),
  },
  Container_Centered: {
    flex: 1,
    alignSelf: "center",
  },
  Container_Audio_Buttons: {
    flex: 1,
    padding: crs(wp("3%"), hp("3%")),
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
  Home_Text_Instructions: {
    fontSize: wp("4%") < hp("4%") ? wp("4%") : hp("4%"),
    fontWeight: "bold",
    textAlign: "justify",
    width: wp("75%"),
    lineHeight: hp("5%"),
    flex: 2,
  },
  image: {
    flex: 1,
    width: wp("75%"),
    height: 0.5625 * wp("75%"),
    resizeMode: "center",
    alignSelf: "center",
  },
  image_PlayButton: {
    flex: 1,
    width: crs(wp("7%"), hp("7%")),
    height: crs(wp("7%"), hp("7%")),
    resizeMode: "center",
    paddingVertical: hp("2%"),
  },
  SearchBar_TextInput: {
    height: hp("5%"),
    borderRadius: hp("3%"),
    borderWidth: 1,
    width: wp("40%") < 400 ? wp("40%") : 400,
    alignSelf: "center",
    textAlign: "center",
  },
  SearchBar_TextInput_Subfolder: {
    height: hp("3%"),
    borderRadius: hp("3%"),
    borderWidth: 1,
    justifyContent: "center",
    width: wp("25%") < 300 ? wp("25%") : 300,
    alignSelf: "center",
    textAlign: "center",
  },
  Tabs_Labels: {
    alignItems: "center",
    justifyContent: "space-evenly",
    top: 0,
    textAlign: "center",
  },
  Text_AudioLabels: {
    fontSize: crs(wp("4%"), hp("4%")),
    color: "black",
    textAlignVertical: "center",
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginVertical: hp("2%"),
  },
  Text_VideoDescriptions: {
    fontSize: crs(wp("4%"), hp("4")),
    color: "#60666c",
    textAlignVertical: "center",
    textAlign: "justify",
    fontWeight: "bold",
    // textTransform: "uppercase",
    // paddingVertical: hp("2%"),
    // flex: 1,
  },
  Text_PlaylistLabel: {
    fontSize: crs(wp("4%"), hp("4%")),
    color: "#60666c",
    textAlignVertical: "center",
    textAlign: "justify",
    fontWeight: "bold",
    // textTransform: "uppercase",
    marginVertical: hp("2%"),
  },
  Therapy_Button: {
    justifyContent: "center",
    margin: wp("5%") < hp("5%") ? wp("5%") : hp("5%"),
    textAlign: "center",
  },
  Therapy_Image: {
    borderRadius: hp("1%"),
    height: wp("25%") < hp("25%") ? wp("25%") : hp("25%"),
    width: wp("25%") < hp("25%") ? wp("25%") : hp("25%"),
  },
  Therapy_Logo: {
    width: wp("10%") < hp("10%") ? wp("75%") : wp("25%"),
    height: wp("10%") < hp("10%") ? 0.5625 * wp("75%") : 0.5625 * wp("25%"),
    resizeMode: "center",
    marginVertical: hp("2%"),
  },
  Therapy_Row_Views: {
    flexDirection: "row",
    marginTop: wp("10%") > hp("10%") ? wp("10%") : hp("5%"),
  },
  Therapy_Text: {
    fontSize: wp("3%") < hp("3%") ? wp("3%") : hp("3%"),
    fontWeight: "bold",
  },
  Video_Player: {
    alignSelf: "center",
    resizeMode: "center",
  },
  Website_Preview: {
    height: 0.75 * wp("75%"),
    width: wp("75%"),
    // flex: 1,
    // paddingVertical: hp("10%"),
    // marginVertical: hp("2%"),
    alignSelf: "center",
    resizeMode: "contain",
  },
});
