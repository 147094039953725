import * as React from "react";
import { Text, View, Image, SafeAreaView } from "react-native";
import logo from "../assets/university_name.png";
import { styles } from "../Style/styles.js";

export function AboutPage() {
  return (
    <SafeAreaView style={styles.AboutContainer}>
      <View style={styles.AboutLogoContainer}>
        <Text style={styles.AboutPageHeaders}>Designed/Developed by:</Text>
        <Image style={styles.image} source={logo} />
      </View>
      <View style={styles.AboutBodyContainer}>
        <View style={styles.AboutTitleContainer}>
          <Text style={styles.AboutPageHeaders}>CMIT/ATTIC Director:</Text>
          <Text style={styles.AboutPageWords}>Dr. Deepak Khazanchi</Text>
        </View>
        <View style={styles.AboutTitleContainer}>
          <Text style={styles.AboutPageHeaders}>Software Engineer:</Text>
          <Text style={styles.AboutPageWords}>Kelvin Chin</Text>
        </View>
        <View style={styles.AboutTitleContainer}>
          <Text style={styles.AboutPageHeaders}>Infrastructure Advisor:</Text>
          <Text style={styles.AboutPageWords}>Dr. Sriram Srinivasan</Text>
        </View>
        <View style={styles.AboutTitleContainer}>
          <Text style={styles.AboutPageHeaders}>Lead Student Developers:</Text>
          <Text style={styles.AboutPageWords}>Terrah Quinlan, AJ Whiting</Text>
        </View>
        <View style={styles.AboutTitleContainer}>
          <Text style={styles.AboutPageHeaders}>Quality Analyst:</Text>
          <Text style={styles.AboutPageWords}>Matt Wissing</Text>
        </View>
        <View style={styles.AboutTitleContainer}>
          <Text style={styles.AboutPageHeaders}>Graphic Designer:</Text>
          <Text style={styles.AboutPageWords}>Kelly Kim</Text>
        </View>
      </View>
    </SafeAreaView>
  );
}
