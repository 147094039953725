import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Tabs from "./navigation/tabs";
import DrawerNav from "./navigation/drawer";
import { SafeAreaView } from "react-navigation";
import { firebaseConfig } from "./Config/firebaseConfig";

const App = () => {
  // return (
  //   <NavigationContainer>
  //     <Tabs />
  //   </NavigationContainer>
  // );
  return <DrawerNav />;
};
export default App;
