import * as React from "react";
import { Text, View, Image, SafeAreaView } from "react-native";
import { styles } from "../../../Style/styles.js";
import { ScrollView } from "react-native-web";
import ReactPlayer from "react-player";
import config from "../../../Config/ProjectConfig.json";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import { useIsFocused } from "@react-navigation/native";
import { useState } from "react";
import { firebaseAnalytics } from "../../../Config/firebaseConfig";
import { logEvent } from "firebase/analytics";

export default function Relaxation() {
  const videos = config.Therapy_Meditation_URL_Media;
  const isFocused = useIsFocused();

  const handlePlay = (videoIndex) => {
    switch (videoIndex) {
      case 5:
        setVideoFourPlaying(true);
        logEvent(firebaseAnalytics, "Relaxation-" + videos[4].label, {
          video_url: videos[4].source,
          video_type: "single-video",
          video_page: "Meditation - Relaxation",
        });
        break;
    }
  };

  const [video4Playing, setVideoFourPlaying] = useState(false);

  return (
    <ScrollView
      contentContainerStyle={styles.Container_Content_ScrollView_AromaRelax}
    >
      <View style={styles.Container_Content_Videos}>
        <View style={styles.Container_Video}>
          <View style={styles.Container_VideoDescription}>
            <Text style={styles.Text_VideoDescriptions}>
              {videos[4].description}
            </Text>
          </View>
        </View>
        <View
          style={{
            alignSelf: "center",
          }}
        >
          <ReactPlayer
            height={0.5625 * wp("75%")}
            width={wp("75%")}
            url={videos[4].source}
            playing={video4Playing && isFocused}
            onPlay={() => handlePlay(5)}
          />
        </View>
      </View>
    </ScrollView>
  );
}
