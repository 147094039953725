import * as React from "react";
import {Text, View, Image, SafeAreaView, TextInput} from "react-native";
import logo from "../assets/UNMClogo.png";
import {styles} from "../Style/styles.js";
import {useState, useEffect} from "react";
import {Button} from "../components/button";
import config from "../Config/ProjectConfig.json";
import {AboutPage} from "./AboutPage";
import {ScrollView} from "react-native-web";
import {useIsFocused} from "@react-navigation/native";
import ReactPlayer from "react-player";
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from "react-native-responsive-screen";
import {firebaseAnalytics} from "../Config/firebaseConfig";
import {logEvent} from "firebase/analytics";

export default function HomeScreen() {
  const handlePlay = (videoIndex) => {
    switch (videoIndex) {
      case 0:
        setPlaylistPlaying(true);
        break;
    }
  };
  const isFocused = useIsFocused();
  const [playlistPlaying, setPlaylistPlaying] = useState(false);

  useEffect(() => {
    // write your code here, it's like componentWillMount
    //onScreenLoad(); //comment this out to bypass survey requirements
  }, []);
  return (
    <ScrollView contentContainerStyle={styles.Container_Content_ScrollView_Home}>
      <View style={styles.Container_Logo}>
        <Image style={styles.image} source={logo}/>
      </View>
      <Text style={styles.Home_Text_Instructions}>
        {config.Home_Instructions}
      </Text>
    </ScrollView>
  );
}
